<template>
  <div class="user-presentation-wrapper d-xl-none">
    <div class="container">
      <div class="user-presentation d-flex justify-content-start align-items-start">
        <div class="user-presentation-img">
          <img src="https://via.placeholder.com/72" alt="">
        </div>
        <div class="user-presentation-content">
          <p class="user-presentation-title">Olá {{ this.$root.userInfo.name }}!</p>
          <div class="user-presentation-info d-flex justify-content-start align-items-center">
            <p>Plano Grátis</p>
            <p><span>0</span> Créditos</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "user-presentation"
}
</script>

<style scoped>

</style>