<template>
  <div class="home-banner-wrapper box-top-banner">
    <Slick ref="slick" :options="slickOptions" class="home-banner-slider" v-if="showHomeBanner" @init="handleSliderInit" @afterChange="handleSliderAfterChange"  @beforeChange="handleSliderBeforeChange">
      <div class="home-banner-slider-item" v-for="(item,index) in areaItems.media.general" :key="index">
        <div class="home-banner-image" v-if="item">
          <img :src="item.image" :alt="item.title">
          <div class="home-banner-video" v-if="item.link">
            <div class="home-banner-video-item" :videoId="'https://player.vimeo.com/video/' + item.link"></div>
          </div>
        </div>
        <div class="home-banner-content-wrapper">
          <div class="container d-flex justify-content-center align-items-center">
            <div class="home-banner-content">
              <p class="home-banner-title">{{ item.title }}</p>
              <p class="home-banner-subtitle">{{ item.description }}</p>
              <a :href="item.link" class="home-banner-btn generic-btn white big-text" v-if="item.link">Treino Personalizado</a>
            </div>
          </div>
        </div>
      </div>
    </Slick>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import axios from "axios";
import Player from '@vimeo/player';

export default {
  name: 'Slider',
  components: {
    Slick,
  },
  data() {
    return {
      errors: [],
      lang: this.$root.siteLang,
      areaItems: [],
      showHomeBanner: false,
      slickOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: false,
        arrows: false,
        dots: true,
        infinite: true,
        swipeToSlide: true,
        touchMove: true,
        speed: 1000,
        lazyLoad: 'progressive'
      },
      timeout: '',
      videoPlayers: [],
    }
  },
  created() {
    axios
        .get(process.env.VUE_APP_API_URL + 'areas/cp_30?with_category_title=0&with_seo=0&lang=' + this.lang)
        .then((response) => {
          this.areaItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {
          if ( typeof this.areaItems.media.general != 'undefined' ) {
            if (this.areaItems.media.general.length > 0) {
              this.showHomeBanner = true;
              this.$root.headerFixed = true;
            } else {
              this.showHomeBanner = false;
              this.$root.headerFixed = false;
            }
          } else {
            this.showHomeBanner = false;
            this.$root.headerFixed = false;
          }
        });
  },
  methods: {
    nextSlide() {
      this.$refs.slick.next();
    },
    playVideo(slideItem, slideIndex) {
      if ( slideIndex in this.videoPlayers ) {
        this.videoPlayers[slideIndex].play();
      } else {
        let videoItem = slideItem.getElementsByClassName('home-banner-video-item')[0];
        let videoID = videoItem.getAttribute('videoId');

        let videoOptions = {
          id: videoID,
          controls: false,
          loop: false,
          muted: true,
          title: false,
        };

        let player = new Player(videoItem, videoOptions);
        player.play();

        this.videoPlayers[slideIndex] = player;

        let ref = this;
        player.on('ended', function () {
          ref.nextSlide();
          player.setCurrentTime(0);

          videoItem.classList.add('hidden');
        });

        player.on('play', function () {
          videoItem.classList.remove('hidden');
        });
      }
    },
    handleSliderInit() {
      if ( document.querySelector(".slick-active").getElementsByClassName('home-banner-video-item').length > 0 ) {
        let slideItem = document.querySelector(".slick-active");
        let slideIndex = slideItem.getAttribute('data-slick-index');

        this.playVideo(slideItem, slideIndex);
      } else {
        let ref = this;
        this.timeout = setTimeout(function (){
          ref.nextSlide();
        },3000);
      }
    },
    handleSliderBeforeChange(event, slick, currentSlide, nextSlide) {
      if ( this.videoPlayers[currentSlide] != undefined ) {
        this.videoPlayers[currentSlide].pause();
      }
    },
    handleSliderAfterChange(event, slick, currentSlide) {
      clearTimeout(this.timeout);

      if ( document.querySelector(".slick-active").getElementsByClassName('home-banner-video-item').length > 0 ) {
        let slideItem = document.querySelector(".slick-active");
        this.playVideo(slideItem, currentSlide);
      } else {
        let ref = this;
        this.timeout = setTimeout(function (){
          ref.nextSlide();
        },3000);
      }
    },
  }
};
</script>