<template>
  <div class="button-toggle-wrapper section-padding">
    <div class="container d-flex justify-content-center align-items-center">
      <div class="button-toggle active-1">
        <button type="button" class="button-toggle-btn" @click="buttonToggle">Para Hoje</button>
        <button type="button" class="button-toggle-btn" @click="buttonToggle">Outros Treinos</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "button-toggle",
  methods: {
    buttonToggle(e) {
      let element = e.currentTarget;
      let elementIndex = Array.from(element.parentElement.children).indexOf(element) + 1;

      let parent = element.closest('.button-toggle');

      parent.classList.remove('active-1');
      parent.classList.remove('active-2');
      parent.classList.remove('active-3');
      parent.classList.add('active-' + elementIndex);
    }
  }
}
</script>