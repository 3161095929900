<template>
  <div class="workout-item">
    <router-link :to="{ name: 'challenges-detail', params:{id:$root.getLink(challengeItem)}}" class="workout-item-link" v-if="!!challengeItem.media.general">
      <div class="workout-item-image">
        <img :src="challengeItem.media.general[0].original" alt="">
      </div>
      <div class="workout-item-content">
        <p class="workout-item-title">{{ challengeItem.title }}</p>
        <p class="workout-item-subtitle" v-if="!!challengeItem.isActive && challengeItem.isActive">A Decorrer…</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import bookmark from "@/components/svg/bookmark.vue";
import lock2 from "@/components/svg/lock2.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";

export default {
  name: "workout-item",
  components : {
    bookmark,
    lock2,
    clock,
    level,
    fire
  },
  props: {
    challengeItem: {
      required: true,
    },
  },
}
</script>