<template>
  <div class="workout-item">
    <router-link :to="{ name: 'workout-detail', params:{id:$root.getLink(workoutHighlightItem)}}" class="workout-item-link">
      <div class="workout-item-image">
        <img :src="workoutHighlightItem.media.general[0].image" alt="">
      </div>
      <div class="workout-item-top d-flex justify-content-end align-items-center">
        <button type="button" class="workout-item-bookmark">
          <bookmark/>
        </button>
      </div>
      <div class="workout-item-content">
        <p class="workout-item-title">{{ workoutHighlightItem.title }}</p>
        <div class="workout-item-tags">
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutHighlightItem.duration && workoutHighlightItem.duration != ''">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <clock/>
            </div>
            <p>{{ workoutHighlightItem.duration }}</p>
          </div>
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutHighlightItem.itensity && workoutHighlightItem.itensity != ''">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <level/>
            </div>
            <p>{{ workoutHighlightItem.itensity }}</p>
          </div>
          <div class="workout-item-tag-item d-flex justify-content-center align-items-center" v-if="!!workoutHighlightItem.level && workoutHighlightItem.level != ''">
            <div class="workout-item-tag-item-icon d-none d-lg-flex justify-content-center align-items-center">
              <fire/>
            </div>
            <p>{{ workoutHighlightItem.level }}</p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import bookmark from "@/components/svg/bookmark.vue";
import lock2 from "@/components/svg/lock2.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";

export default {
  name: "workout-item",
  components : {
    bookmark,
    lock2,
    clock,
    level,
    fire
  },
  props: {
    workoutHighlightItem: {
      required: true,
    },
  },

}
</script>